
.vouchers-container
  display flex
  flex-direction column
  gap 16px
  // border-radius: 8px
  // padding 16px

.apply-voucher-container
  border-radius: 8px
  background-color: #f5f5f7
  display flex
  flex-direction column
  align-items center
  padding 16px
  gap 16px

.apply-voucher
  width 100%
  display flex
  flex-direction row
  flex-wrap nowrap
  align-items center
  justify-content flex-end
  gap 16px

form
  display flex
  flex-direction row
  align-items center

.message
  width 100%
  flex-basis 100%
  color red
  font-size 14px

.message-success
    width 100%

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
    margin: auto 0
}

::v-deep .v-btn.btn-remove {
    padding: 0 !important
    min-width: 50px !important
    color: rgba(0,0,0, 0.45) !important
}
